:root {
  --branding-primary: #3cc2dd;
  --branding-darker: #2a889b;
  --branding-light: #a8e1f0;
  --branding-accent: #fb9d50;
  --branding-accent-light: #fdbc88;
  --textbg-dark5: #0c0c0d;
  --textbg-dark4: #2f2f34;
  --textbg-dark3: #6a6a73;
  --textbg-dark2: #c2c2c3;
  --textbg-dark1: #e8e8e8;
  --textbg-light4: #c0e3f2;
  --textbg-light3: #d5eef6;
  --textbg-light2: #ecfafd;
  --textbg-light1: #f6fcfe;
  --system-yellow3: #e9d310;
  --system-yellow2: #fff389;
  --system-yellow1: #fffce0;
  --system-blue3: #175ac7;
  --system-blue2: #5796fe;
  --system-blue1: #d8e7ff;
  --system-red3: #c71a1a;
  --system-red2: #eb7979;
  --system-red1: #ffcaca;
  --system-green3: #02bf64;
  --system-green2: #25f18f;
  --system-green1: #a3ffd3;
}

/* Text Hierarchy */
.heading1 {
  font-size: 36px;
}

.heading2 {
  font-size: 24px;
}

.heading3 {
  font-size: 18px;
}

.body-def {
  font-size: 14px;
}

.body-md {
  font-size: 14px;
  font-weight: 500;
}

.body-sb {
  font-size: 14px;
  font-weight: 600;
}

.body-b {
  font-size: 14px;
  font-weight: 700;
}

.subheading {
  font-size: 12px;
  font-weight: 500;
}

/* Main */
.main-left {
  float: left;
  width: 25%;
}

.main-right {
  float: right;
  width: 75%;
}

/* Dashboard */
.dashboard {
  padding: 15px;
}

.dashboard-left {
  padding: 30px;
}

.dashboard-right {
  padding: 10px;
}

.greetings {
  border-color: var(--textbg-dark3);
  border-style: solid;
  padding: 20px;
  margin-bottom: 20px;
}

.calendar {
  margin-bottom: 10px;
}

.dashboard-app {
  padding-top: 50px;
}

.calendar {
  display: flex;
  justify-content: center;
  padding: 10px 5px 15px;
}

/* Applicants */
.app-left {
  padding: 20px;
}

.app-right {
  display: flex;
  flex-direction: row;
  padding: 20px;
  text-align: right;
  justify-content: flex-end;
}

.app-head {
  padding: 5px 10px 20px;
}

/* Employees */
.emp-left {
  padding: 20px;
}

.emp-right {
  display: flex;
  flex-direction: row;
  padding: 20px;
  text-align: right;
  justify-content: flex-end;
}

.avatar-main {
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-end;
}

.name-cell {
  display: flex;
  align-items: center;
}

/* Payment Schedule */
.ps-left {
  padding: 20px;
}

.ps-right {
  display: flex;
  flex-direction: row;
  text-align: right;
  justify-content: flex-end;
}

/* Buttons */
.btn-primary {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--branding-primary);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--textbg-dark4);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-primary:hover {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--branding-darker);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--textbg-light4);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-primary:disabled,
.btn-secondary:disabled {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--textbg-dark1);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--textbg-dark2);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-secondary {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--textbg-dark1);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--textbg-dark4);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-secondary:hover {
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--branding-light);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--textbg-dark5);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-text {
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  font-family: "Archivo";
  font-size: 14px;

  color: var(--textbg-dark5);
  background-color: transparent;
  height: 37px;
  border: none;
}

.btn-archive {
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--system-red3);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--textbg-light2);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

.btn-archive:hover {
  text-decoration: none;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 10px;
  background-color: var(--system-red1);
  border-radius: 10px;
  border: none;
  font-family: "Archivo";
  font-weight: 600;
  font-size: 14px;
  color: var(--system-red3);
  height: 37px;
  margin-left: 5px;
  margin-right: 5px;
}

/* Drawer */
.info-title {
  color: var(--textbg-dark3);
}

.info-data {
  color: var(--textbg-dark4);
  margin-bottom: 10px;
}

/* Progress Bar */
.bar-completed {
  background-color: var(--branding-primary);
}

.bar-container {
  background-color: var(--textbg-dark3);
}

/* Spacing */
.space-one {
  line-height: 80px;
}

.space-two {
  position: relative;
  right: 120px;
  top: 20px;
}
.space-three {
  position: relative;
  right: 170px;
  top: 20px;
}
.space-four {
  position: relative;
  right: 110px;
  top: 20px;
}

.progress-space-one {
  /* position: relative; */
  right: 500px;
  top: 50px;
}

.progress-space-two {
  /* position: relative; */
  right: 250px;
  top: 20px;
}

.info-padding {
  padding-top: 30px;
}

.center-align {
  text-align: center;
}

.center {
  margin: auto;
  width: 50%;
  border: 3px solid green;
  padding: 10px;
}
.ps-right-one {
  display: flex;
  justify-content: flex-end;
}

.borderstyle-mix {
  border-style: none none solid none;
  border-width: 1px;
  border-color: var(--textbg-dark2);
}

.text-area-padding {
  padding: 10px;
}

.display-flex {
  object-fit: fill;
}

.button-ur {
  padding-top: 20px;
}

.space-five {
  position: relative;
  right: 60px;
}

.remove-deco{
  text-decoration: none;
  color: black;
}